/* tslint:disable */
/* eslint-disable */
/**
 * Woxzia Portal API
 * Backend API for the frontend
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type {
  AuditItem,
  CommonCode,
  Form,
  FormDefinition,
} from '../models';
import {
    AuditItemFromJSON,
    AuditItemToJSON,
    CommonCodeFromJSON,
    CommonCodeToJSON,
    FormFromJSON,
    FormToJSON,
    FormDefinitionFromJSON,
    FormDefinitionToJSON,
} from '../models';
export interface DefaultApiGetFormRequest {
    key: string;
}
export interface DefaultApiListAuditsRequest {
    cursor?: number;
}
export interface DefaultApiListFormsRequest {
    all?: boolean;
}
/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
    /**
     */
    async getDiagnoseCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CommonCode>>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/meta/codes/icd`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommonCodeFromJSON));
    }
    /**
     */
    async getDiagnoseCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CommonCode>> {
        const response = await this.getDiagnoseCodesRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getFormRaw(requestParameters: DefaultApiGetFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormDefinition>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getForm.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/forms/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FormDefinitionFromJSON(jsonValue));
    }
    /**
     */
    async getForm(requestParameters: DefaultApiGetFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormDefinition> {
        const response = await this.getFormRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getFunctionCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CommonCode>>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/meta/codes/icf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommonCodeFromJSON));
    }
    /**
     */
    async getFunctionCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CommonCode>> {
        const response = await this.getFunctionCodesRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getTreatmentCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CommonCode>>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/meta/codes/kva`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommonCodeFromJSON));
    }
    /**
     */
    async getTreatmentCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CommonCode>> {
        const response = await this.getTreatmentCodesRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async listAuditsRaw(requestParameters: DefaultApiListAuditsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AuditItem>>> {
        const queryParameters: any = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/auditlog`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuditItemFromJSON));
    }
    /**
     */
    async listAudits(requestParameters: DefaultApiListAuditsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AuditItem>> {
        const response = await this.listAuditsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async listFormsRaw(requestParameters: DefaultApiListFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Form>>> {
        const queryParameters: any = {};
        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/api/v1/forms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FormFromJSON));
    }
    /**
     */
    async listForms(requestParameters: DefaultApiListFormsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Form>> {
        const response = await this.listFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}