import { Api } from '@/lib/di/api'
import { getText, Selectable } from '@/lib/select'
import { MaybeRef, Nullable } from '@/lib/types'
import { onMounted, reactive, ref, unref, watch } from 'vue'
import { Investigation, InvestigationApi, InvestigationCreate, PatientApi } from '../api/index'
import { Loadable, useLoadable } from './loadable'
import { format, formatISO, parseISO } from 'date-fns'
import { faker } from '@faker-js/faker/locale/sv'

export interface InvestigationFormOptions {
  types: Selectable[],
  forms: Selectable[],
}

export function useInvestigationOptions () {
  const options: InvestigationFormOptions = reactive<InvestigationFormOptions>({
    types: [
      { value: '', text: 'Välj utredning' },
      { value: 'medical', text: 'Medicinsk utredning' },
      { value: 'fmu', text: 'FMU - Försäkringsmedicinsk utredning' },
      { value: 'rehabilitation', text: 'Rehabilitering' }
    ],
    forms: []
  })
  onMounted(async () => {
    options.forms = (await Api.get().listForms()).map(f => ({ value: f.id, text: f.name }))
  })
  return { options, getTypeText: (val: string) => getText(options.types, val) }
}

interface Meetdate {
  meettime: string;
  meetdate: string;
}

export type InvestigationFormModel = Omit<InvestigationCreate, 'meetdate' | 'patient_id'> & Meetdate

export function usePatientInvestigationEdit (investigationId: MaybeRef<string>, onSuccess: (id: string) => void) {
  const api = Api.investigation()
  const { form, ...rest } = usePatientInvestigationForm(async (data: InvestigationFormModel) => {
    const { meetdate, meettime, ...rest } = data
    const { id } = await api.update({
      investigationId: unref(investigationId),
      investigationUpdate: {
        ...rest,
        meetdate: formatISO(parseISO(meetdate + 'T' + meettime + ':00'))
      }
    })
    onSuccess(id)
  })
  onMounted(async () => {
    const { patientId, form: form_id, meetdate, sickday, id, startdate, ...rest } = await api.get({
      investigationId: unref(investigationId)
    })

    Object.assign(form, rest)
    form.meetdate = format(parseISO(meetdate), 'yyyy-MM-dd')
    form.meettime = format(parseISO(meetdate), 'HH:mm')
    form.sickday = format(parseISO(sickday), 'yyyy-MM-dd')
  })

  return { form, ...rest }
}

export function usePatientInvestigationCreate (patient_id: MaybeRef<string>, onSuccess: (id: string) => void) {
  const api = Api.investigation()
  return usePatientInvestigationForm(async (data: InvestigationFormModel) => {
    const { meetdate, meettime, ...rest } = data
    const { id } = await api.create({
      investigationCreate: {
        ...rest,
        patient_id: unref(patient_id),
        meetdate: formatISO(parseISO(meetdate + 'T' + meettime))
      }
    })
    onSuccess(id)
  })
}

function usePatientInvestigationForm (onSubmit: (form: InvestigationFormModel) => Promise<void>) {
  const error = ref<string | undefined>()
  const form = reactive<InvestigationFormModel>({
    name: '',
    reason: '',
    meetdate: '',
    meettime: '08:00',
    sickday: format(new Date(), 'yyyy-MM-dd'),
    type: '',
    form_id: ''
  })
  const validation = reactive<Nullable<InvestigationFormModel>>({
    name: null,
    reason: null,
    meetdate: null,
    meettime: null,
    sickday: null,
    type: null,
    form_id: null
  })
  const { options } = useInvestigationOptions()

  // Set default form_id when fetched
  watch(() => options.forms, (newer, older) => {
    if (older.length === 0 && newer.length > 0) {
      form.form_id = newer[0].value as string
    }
  })

  function fakeFill () {
    form.name = faker.lorem.sentence()
    form.reason = faker.lorem.paragraph(3)
    form.type = faker.helpers.arrayElement(options.types.map(t => t.value as string).filter(s => !!s)) ?? 'fmu'
    form.meetdate = faker.date.soon(7).toISOString().replace(/(.*)T.*/gi, '$1')
    form.sickday = faker.date.recent(7).toISOString().replace(/(.*)T.*/gi, '$1')
    form.form_id = faker.helpers.arrayElement(options.forms.map(t => t.value as string).filter(s => !!s))!
  }

  function clearValidation () {
    let k: keyof typeof validation
    for (k in validation) {
      validation[k] = null
    }
  }

  watch(form, clearValidation)

  function validate (): boolean {
    clearValidation()

    if (form.name.length < 3) {
      validation.name = 'Du måste ange ett namn på utredningen'
    }
    if (form.reason.length < 3) {
      validation.reason = 'Du måste ange en anledning'
    }
    if (!form.meetdate) {
      validation.meetdate = 'Du måste ange det bokade besökets datum'
    }
    if (!form.meettime) {
      validation.meettime = 'Du måste ange det bokade besökets tid'
    }
    if (!form.type) {
      validation.type = 'Du måste ange vilken typ av utredning'
    }
    if (!form.sickday) {
      validation.sickday = 'Du måste ange en första sjukdag'
    }

    return !!(
      validation.meetdate ??
      validation.meettime ??
      validation.sickday ??
      validation.name ??
      validation.reason ??
      validation.type ??
      validation.form_id
    )
  }

  async function submit () {
    if (validate()) {
      return
    }
    try {
      await onSubmit(form)
    } catch (err) {
      console.error(err)
      if (err instanceof TypeError && err.message === 'Failed to fetch') {
        error.value = 'Anslutningen misslyckades. Kontrollera din anslutning och försök igen.'
      } else if (err instanceof Response) {
        const body = await err.text()
        error.value = err.statusText + (body ? `: ${body}` : '')
      } else {
        error.value = JSON.stringify(err, null, 2)
      }
    }

  }

  return { submit, error, form, validation, options, fakeFill }
}

export function usePatientInvestigation (investigationId: MaybeRef<string>): Loadable<Investigation> {
  return useLoadable(Api.investigation(), async api => await api.get({ investigationId: unref(investigationId) }))
}

export function usePatientFromInvestigation (investigationId: MaybeRef<string>) {
  return useLoadable([Api.patient(), Api.investigation()], async ([patients, investigations]: [PatientApi, InvestigationApi]) => {
    const { patientId } = await investigations.get({ investigationId: unref(investigationId) })
    return await patients.get({ patientId })
  })
}
