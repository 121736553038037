import { ResponseError } from '../api/runtime'
import { mapValues } from 'lodash-es'
import { isSSN, isTel } from '@/lib/common'
import { Api } from '@/lib/di/api'
import { NonNulls, MaybeRef } from '@/lib/types'
import { onMounted, ref, unref } from 'vue'
import { ErrorValue, PatientCreate, PatientListing, PatientUpdate } from '../api/index'
import { BackendError, errorFromResponse, useLoadable } from './loadable'
import { useFormValidation } from './simple-form'
import z from 'zod'
import { faker } from '@faker-js/faker/locale/sv'

export function usePatients () {
  const api = Api.patient()

  const error = ref<BackendError | { message: string, status: number } | null>(null)
  const loading = ref<boolean>(true)

  const patients = ref<PatientListing[]>([])

  async function load () {
    loading.value = true
    try {
      const result = await api.list()
      patients.value = result
    } catch (e) {
      error.value = await errorFromResponse(e)
      patients.value = []
    } finally {
      loading.value = false
    }
  }

  onMounted(load)

  return { error, loading, patients, load }
}

export function useFormSubmit (
  onValidate: () => boolean,
  onFormError: (error: ErrorValue) => boolean,
  onExecute: () => Promise<void>
) {

  const error = ref<BackendError | { message: string } | null>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleNetworkError (e: TypeError) {
    error.value = {
      message: 'Anslutningen misslyckades. Kontrollera din anslutning och försök igen.',
      status: -1
    }
  }

  async function submit () {
    try {
      error.value = undefined
      if (onValidate()) {
        await onExecute()
      }
    } catch (err) {
      if (err instanceof ResponseError) {
        const ev = await errorFromResponse(err)
        if (ev.extra && !onFormError(ev.extra)) {
          error.value = ev
        }
      } else if (err instanceof TypeError && err.message === 'Failed to fetch') {
        handleNetworkError(err)
      } else {
        throw err
      }
    }
  }

  return { error, submit }
}

export type PatientCreateFormModel = PatientCreate
export type PatientCreateGroupState = ReturnType<typeof useCreatePatient>['groupstate']['value']
export type PatientCreateInputState = ReturnType<typeof useCreatePatient>['inputstate']['value']
export type PatientCreateFeedback = ReturnType<typeof useCreatePatient>['feedback']['value']

export function useCreatePatient (onSuccess: (id: string) => void) {
  const patients = Api.patient()

  const { data: form, feedback, groupstate, inputstate, hasErrors, clear, loadErrors } = useFormValidation(null, {
    name: { start: '', schema: z.string().min(3, 'Månste vara minst 3 tecken') },
    ssn: { start: '', schema: z.string().regex(isSSN, 'Skriv på formatet yyyymmdd-nnnn') },
    email: { start: '', schema: z.string().email('Måste vara en email med @') },
    tel: { start: '', schema: z.string().regex(isTel, 'Måste vara ett telenummer') }
  })

  const { submit, error } = useFormSubmit(() => hasErrors.value, onFormError, async () => {
    const { id } = await patients.create({ patientCreate: form })
    clear()
    onSuccess(id)
  })

  function onFormError (e: ErrorValue): boolean {
    const out: { [P in keyof typeof form]: null | string } = mapValues(form, () => null)
    if (e.prisma?.type === 'NOT_UNIQUE' && e.prisma?.target?.includes('email')) {
      out.email = 'Det finns redan en patient med denna epost'
    }
    if (e.prisma?.type === 'NOT_UNIQUE' && e.prisma?.target?.includes('ssn')) {
      out.ssn = 'Det finns redan en patient med detta personnumer'
    }
    if (e.tsoa && 'init.ssn' in e.tsoa) {
      out.ssn = 'Ange ett giltigt svenskt personnummer / samordningsnummer'
    }
    if (e.tsoa && 'init.email' in e.tsoa) {
      out.email = 'Ange en giltig epost'
    }
    if (e.tsoa && 'init.tel' in e.tsoa) {
      out.tel = 'Ange en giltig telefonnummer'
    }
    loadErrors(out)
    return Object.values(out).every(v => v === null)
  }

  function fakeFill () {
    const first = faker.name.firstName()
    const last = faker.name.lastName()
    form.email = faker.internet.email(first, last)
    form.tel = faker.phone.number()
    form.name = `${first} ${last}`
    form.ssn = `${faker.datatype.number({ min: 1900, max: 2000 })}${faker.datatype.number({ min: 10, max: 12 })}${faker.datatype.number({ min: 10, max: 28 })}-${faker.datatype.number({ min: 1000, max: 9999 })}`
  }

  return { submit, error, form, groupstate, inputstate, feedback, fakeFill }
}

export type PatientEditFormModel = PatientUpdate
export type PatientEditGroupState = ReturnType<typeof useEditPatient>['groupstate']['value']
export type PatientEditInputState = ReturnType<typeof useEditPatient>['inputstate']['value']
export type PatientEditFeedback = ReturnType<typeof useEditPatient>['feedback']['value']

export function useEditPatient (patientId: MaybeRef<string>, onSuccess: (patientId: string) => void) {

  const { data: form, feedback, groupstate, inputstate, hasErrors, clear, loadErrors } = useFormValidation(null, {
    name: { start: '', schema: z.string().min(3, 'Månste vara minst 3 tecken') },
    email: { start: '', schema: z.string().email('Måste vara en email med @') },
    tel: { start: '', schema: z.string().regex(isTel, 'Måste vara ett telenummer') }
  })

  const api = Api.patient()

  onMounted(async () => {
    const { email, tel, name } = await api.get({ patientId: unref(patientId) })
    Object.assign(form, { email, tel, name })
  })

  const { submit, error } = useFormSubmit(() => hasErrors.value, onFormError, async () => {
    await api.update({ patientId: unref(patientId), patientUpdate: form as NonNulls<typeof form> })
    clear()
    onSuccess(unref(patientId))
  })

  function onFormError (e: ErrorValue) {
    const out: { [P in keyof typeof form]: null | string } = mapValues(form, () => null)
    if (e.prisma?.type === 'NOT_UNIQUE' && e.prisma?.target?.includes('email')) {
      out.email = 'Det finns redan en patient med denna epost'
    }
    if (e.tsoa && 'init.email' in e.tsoa) {
      out.email = 'Ange en giltig epost'
    }
    if (e.tsoa && 'init.tel' in e.tsoa) {
      out.email = 'Ange ett giltig telefonnummer'
    }
    loadErrors(out)
    return Object.values(out).every(v => v === null)
  }

  function fakeFill () {
    const first = faker.name.firstName()
    const last = faker.name.lastName()
    form.email = faker.internet.email(first, last)
    form.tel = faker.phone.number()
    form.name = `${first} ${last}`
  }

  return { submit, error, form, feedback, groupstate, inputstate, fakeFill }
}

export function usePatient (patientId: MaybeRef<string | null>) {
  return useLoadable(Api.patient(), async api => await api.get({ patientId: unref(patientId) ?? '' }))
}
