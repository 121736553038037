
import { computed, defineComponent, watch } from 'vue'
import { usePatients } from '@/use/patients'
import { useGlobalLogin, useLoginGuard } from '@/use/login'
import { prettyDate, ssnToGuessedAge } from '@/lib/date'
import { PatientListing } from '../../api/index'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'PatientList',
  components: { },
  setup () {
    const { error, loading, patients } = usePatients()

    const { reload } = useLoginGuard()
    const { login } = useGlobalLogin()

    watch(error, async e => {
      if (e?.status === 401) {
        loading.value = true
        await reload()
      }
    })

    const items = computed(() => (error.value ?? loading.value) ? [] : patients.value)

    const fields = [
      { key: 'name', label: 'Namn', sortable: true },
      { key: 'masked_ssn', label: 'Personnummer', tdClass: 'text-monospace', sortable: true },
      { key: 'age', label: 'Ålder', sortable: true, formatter: (v: string, k: string, item: PatientListing) => ssnToGuessedAge(item.masked_ssn) },
      { key: 'registered', label: 'Registrerades', sortable: true, formatter: (v: string) => prettyDate(v, true, false) },
      { key: 'actions', label: '', tdClass: 'text-right' }
    ]

    const router = useRouter()
    const openUrl = computed(() => login.value?.organisation
      ? document.location.origin + router.resolve({ name: 'OpenWelcome', params: { id: login.value?.organisation.id } }).href
      : null
    )

    return { error, loading, items, fields, openUrl, login }
  },
  metaInfo: {
    title: 'Patienter'
  }
})
