import { format, parseISO, parse, differenceInYears, startOfToday, differenceInDays, differenceInMonths } from 'date-fns'

export function ssnToGuessedAge (ssnMask: string) {
  const years = differenceInYears(startOfToday(), parse(ssnMask.slice(0, 4), 'yyyy', startOfToday()))
  return `~${years} år`
}

export function ssnToAge (ssn: string) {
  return differenceInYears(startOfToday(), parse(ssn.slice(0, 8), 'yyyyMMdd', startOfToday()))
}

export function prettyDate (date: string, relative = false, time = true): string {
  if (relative) {
    const days = differenceInDays(parseISO(date), startOfToday())
    if (days === 0) {
      const suffix = 'idag'
      return format(parseISO(date), 'yyyy-MM-dd HH:mm') + ` (${suffix})`
    } else if (days > 90 || days < -90) {
      const months = differenceInMonths(parseISO(date), startOfToday())
      const suffix = (months > 0 ? `om ${months} månader` : `${Math.abs(months)} månader sedan`)
      return format(parseISO(date), `yyyy-MM-dd ${time ? 'HH:mm' : ''}`) + ` (${suffix})`
    } else {
      const suffix = (days > 0 ? `om ${days} dagar` : `${Math.abs(days)} dagar sedan`)
      return format(parseISO(date), `yyyy-MM-dd ${time ? 'HH:mm' : ''}`) + ` (${suffix})`
    }
  } else {
    return format(parseISO(date), `yyyy-MM-dd ${time ? 'HH:mm' : ''}`)
  }
}
