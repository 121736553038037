import { inject, InjectionKey, provide, reactive, toRefs, UnwrapRef } from 'vue'

export const ConfigComponent: InjectionKey<UnwrapRef<ConfigType>> = Symbol('config')

export interface ConfigType {
  isDebug: boolean;
  isDev: boolean;
}

export class Config {
  static init () {
    const env = process.env
    const appEnv = env.VUE_APP_ENV as string | null

    if (!appEnv || appEnv === 'unset') {
      console.warn(`VUE_APP_ENV was not set properly: ${appEnv}`)
    }

    const config = reactive<ConfigType>({
      isDebug: appEnv === 'debug',
      isDev: appEnv === 'dev' || appEnv === 'debug'
    })

    provide(ConfigComponent, config)

    return { config }
  }

  static get () {
    const config = inject(ConfigComponent, reactive<ConfigType>({} as ConfigType))
    return { ...toRefs(config) }
  }
}
