import { render, staticRenderFns } from "./DiagnoseInput.vue?vue&type=template&id=dae64b36&scoped=true"
import script from "./DiagnoseInput.vue?vue&type=script&lang=ts"
export * from "./DiagnoseInput.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.7.1_vue-template-compiler@2.7.13_webpack@5.97.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae64b36",
  null
  
)

export default component.exports