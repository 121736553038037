import { render, staticRenderFns } from "./PatientCreateInvestigation.vue?vue&type=template&id=45f92537"
import script from "./PatientCreateInvestigation.vue?vue&type=script&lang=ts"
export * from "./PatientCreateInvestigation.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.7.1_vue-template-compiler@2.7.13_webpack@5.97.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports