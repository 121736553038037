
import { computed, defineComponent, ref, toRef } from 'vue'
import { Api } from '@/lib/di/api'
import { useLoadable } from '@/use/loadable'
import { computedAsync } from '@vueuse/core'

export default defineComponent({
  setup () {
    const api = Api.get()
    const formId = ref<string | undefined>(undefined)
    const forms = useLoadable(api,
      async api => await api.listForms(), f => {
        formId.value = f[0].id
      })

    const formMeta = computed(() => forms.data.value?.find(s => s.id === formId.value))
    const formData = computedAsync(async () => {
      if (formId.value) {
        return await api.getForm({ key: formId.value })
      }
      return undefined
    }, undefined)
    const loading = toRef(forms, 'loading')
    return { forms, formId, formData, formMeta, loading }
  },
  metaInfo: {
    title: 'Formulär'
  }
})
