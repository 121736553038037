import { render, staticRenderFns } from "./DiagnoseHint.vue?vue&type=template&id=2737b660&scoped=true"
import script from "./DiagnoseHint.vue?vue&type=script&lang=ts"
export * from "./DiagnoseHint.vue?vue&type=script&lang=ts"
import style0 from "./DiagnoseHint.vue?vue&type=style&index=0&id=2737b660&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.7.1_vue-template-compiler@2.7.13_webpack@5.97.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2737b660",
  null
  
)

export default component.exports